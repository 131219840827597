import React from "react";
// import {SvgXml} from "react-native-svg";
import { SvgXml } from '@cantoo/rn-svg';

export default function MoreHorizontal(props) {

    const more = `<svg height='100px' width='100px'  fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22 22" version="1.1" x="0px" y="0px">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill="#FFFFFF">
                <path d="M3,8 C1.343,8 0,9.343 0,11 C0,12.657 1.343,14 3,14 C4.657,14 6,12.657 6,11 C6,9.343 4.657,8 3,8"></path>
                <path d="M19,8 C17.343,8 16,9.343 16,11 C16,12.657 17.343,14 19,14 C20.657,14 22,12.657 22,11 C22,9.343 20.657,8 19,8"></path>
                <path d="M11,8 C9.343,8 8,9.343 8,11 C8,12.657 9.343,14 11,14 C12.657,14 14,12.657 14,11 C14,9.343 12.657,8 11,8"></path>
            </g>
        </g>
    </svg>`

    const MoreHorizontal = () => <SvgXml xml={more} width={props.width} height={props.height}/>;

    return <MoreHorizontal/>
}