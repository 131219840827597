import React from 'react';
import {Linking, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function AboutApp() {

    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        contentView,
        headerText,
        headerView,
        linkText,
        sectionText,
        unorderedListText
    } = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <View style={[headerView, mtLarge]}>
                    <Text style={headerText}>About this App</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionText, mtSmall]}>
                    	This app was developed at the University of Virginia with funding from the National Cancer Institute of the National Institutes of Health.
                    </Text>
                    
                    <Text style={[sectionText, mtSmall]}>
                    	This app is being made available to participants in a research study led by Dr. David Mohr and Dr. Colleen Stiles-Shields. 
                    	This research study has been approved by the Northwestern University Institutional Review Board (IRB number: STU00211887) and is funded by NIMH grant P50 MH119029.
                    </Text>
                    
                    <Text style={[sectionText, mtSmall]}>
						The research team may be reached via the following channels: <Text
                            style={[linkText, unorderedListText]}
                            onPress={() => {
                                Linking.openURL('mailto:ican4wellness@northwestern.edu')
                            }}
                        >ican4wellness@northwestern.edu</Text> or call toll-free <Text
                            style={[linkText, unorderedListText]}
                            onPress={() => {
                                Linking.openURL('tel:+18556822487')
                            }}
                        >1-855-682-2487</Text>.
                    </Text>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}