import React, {useContext} from 'react';
import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../styles/Global";
import {createEvent} from "../graphql/mutations";
import {AuthContext} from "../contexts/AuthContext";
import {v4 as uuid} from 'uuid';
import PropTypes from 'prop-types';

export default function MainScreen({navigation}) {
    const {background, bodyText, flex, h1, h2, mtXLarge, smallButton, smallButtonText, topicPage} = Styles

    const user = useContext(AuthContext)

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex, topicPage}
                showsVerticalScrollIndicator={false}
            >
                <Text style={[h1, mtXLarge, styles.headerTitle]}>Topics</Text>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/what-is-distress.jpg")}
                        style={[styles.backgroundImage, {top: -75}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>What is distress?</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'What is distress?'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('WhatIsDistress')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/depression.jpg")}
                        style={[styles.backgroundImage, {top: -15}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>Depression: What to look for</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'Depression: What to look for'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('Depression')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/anxiety.jpg")}
                        style={[styles.backgroundImage, {top: -15}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>Anxiety: What to look for</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'Anxiety: What to look for'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('Anxiety')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/prevalence.jpg")}
                        style={[styles.backgroundImage, {top: -30}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>Depression and Anxiety: Occurrence, Causes, and Impact</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'Depression and Anxiety: Occurrence, Causes, and Impact'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('Prevalence')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/dos-and-donts.jpg")}
                        style={[styles.backgroundImage, {top: -10}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>Managing depression and anxiety: Do’s and Don’ts</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'Managing depression and anxiety: Do’s and Don’ts'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('DosAndDonts')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/strategies.jpg")}
                        style={[styles.backgroundImage, {top: -60}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>Managing depression and anxiety: Strategies</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'Managing depression and anxiety: Strategies'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('Strategies')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
                <View style={styles.topicView}>
                    <Image
                        source={require("../../assets/images/help.jpg")}
                        style={[styles.backgroundImage, {top: -80}]}/>
                    <View style={styles.topicContentView}>
                        <View>
                            <Text style={[h2, styles.topicTitle]}>When to seek professional help</Text>
                            {/*<Text style={bodyText}>Need a short message to put here</Text>*/}
                        </View>
                    </View>
                    <TouchableOpacity
                        style={[smallButton, styles.viewTopicButton]}
                        onPress={() => {
                            const input = {
                                input: {
                                    userID: user.username,
                                    eventID: uuid(),
                                    eventName: 'topic-visited',
                                    metadata: JSON.stringify({
                                        description: 'User pressed button from Main Screen to go to topic',
                                        topic: 'When to seek professional help'
                                    }),
                                    createdAt: new Date().toISOString()
                                }
                            }
                            API.graphql(graphqlOperation(createEvent, input))
                            navigation.navigate('Help')
                        }}
                    >
                        <Text style={smallButtonText}>View Topic</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.divider}/>
            </ScrollView>
        </SafeAreaView>
    )
}

MainScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
    backgroundView: {
        backgroundColor: "#00030B",
        flex: 1,
        flexDirection: 'column'
    },
    headerTitle: {
        marginLeft: 16,
        marginBottom: 16,
    },
    topicView: {
        height: 180,
        width: '100%',
        overflow: 'hidden',
        justifyContent: 'space-between'
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: 277,
        resizeMode: 'cover',
        left: 0,
        opacity: 0.25
    },
    topicContentView: {
        padding: 16
    },
    topicTitle: {
        marginBottom: 4
    },
    viewTopicButton: {
        alignSelf: 'flex-start',
        position: 'absolute',
        bottom: 0,
        left: 16
    },
    divider: {
        height: 4,
        width: '100%',
        backgroundColor: '#FFFFFF'
    },
    settingIconView: {
        marginRight: 17,
    }
});