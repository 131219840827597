import {PhoneNumberValidator} from "../types/validators";
import {cleanPhoneNumber} from "../helpers/phoneNumber";

export function phoneNumberValidator(phoneNumber: string): PhoneNumberValidator {
    const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber)

    if(cleanedPhoneNumber === '+1') {
        return {
            isValid: false,
            errorMsg: "Phone number cannot be blank"
        }
    }

    if(cleanedPhoneNumber.length !== 12) {
        return {
            isValid: false,
            errorMsg: "Phone number is not valid"
        }
    }

    return {
        isValid: true,
        value: cleanedPhoneNumber
    }
}