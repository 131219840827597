import React from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";
import PropTypes from 'prop-types';

export default function DosAndDonts({navigation}) {
    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        accentHeaderText,
        contentView,
        headerImageView,
        headerText,
        linkText,
        headerView,
        subHeaderText,
        sectionText,
        unorderedListBullet,
        unorderedListText,
        unorderedListView
    } = TopicStyles
    
    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/dos-and-donts.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>Managing depression and anxiety: Do’s and Don’ts</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionText, mtSmall]}>Fortunately, there are many things we can do to help manage our
                        depression and anxiety. Here are some tips (the Do’s and Don’ts) based on experts and trusted
                        scientific findings. See the topic <Text
                            style={[linkText, unorderedListText]}
                            onPress={() => {
                                navigation.navigate('Strategies')
                            }}
                        >“Managing Depression and Anxiety: Strategies”</Text> for
                        descriptions of mental health coping techniques.</Text>
                    <Text style={[accentHeaderText, mtLarge]}>Do</Text>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Learn more about your symptoms and how they
                            impact you</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Talk to others who will listen without
                            judgment</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Remind yourself that it’s OK to feel sad, afraid,
                            angry, or frustrated</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Engage in meditation, prayer, or other types of
                            spiritual support that are helpful</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Practice mindfulness in a structured course or a
                            self-help resource</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Get regular physical exercise such as walking,
                            yoga, or jogging</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Remember to control your breathing when you’re
                            getting stressed</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Maintain good hygiene (bathing/showering,
                            brushing teeth)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Eat a healthy diet</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Keep a normal sleep schedule</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Make sure to have regular social interactions
                            with loved ones</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Keep a journal to express yourself</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Deal with your symptoms one day at time</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Remember healthy ways of coping that have helped
                            you in the past</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>See a health care professional about specialized
                            treatments if your symptoms are worsening </Text>
                    </View>


                    <Text style={[accentHeaderText, mtLarge]}>Don't</Text>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Blame yourself for your symptoms</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Minimize or make light of your symptoms</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Feel guilty, ashamed, or embarrassed to reach out
                            to others who want to help</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Isolate from others</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Allow yourself to become inactive and
                            withdrawn</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Give up if you can’t manage your symptoms all the
                            time</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Suffer in silence</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Abandon any changes you’ve made because you
                            haven’t seen immediate progress</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Dwell on the past, missed opportunities, or
                            continue to reanalyze painful experiences</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Self-medicate by using drugs, alcohol, food,
                            caffeine, or engage in unhealthy behaviors (e.g., compulsive buying, pornography) to manage
                            your symptom</Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

DosAndDonts.propTypes = {
  navigation: PropTypes.object.isRequired,
};
