import React, {useState, useEffect, createContext} from 'react'
import UnauthenticatedStackScreen from "./UnauthenticatedStack";
import AuthenticatedStackScreen from "./AuthenticatedStack";
import {Auth, Hub} from "aws-amplify";
import {AuthContext} from "../contexts/AuthContext";

export default function AuthNavigator() {
    const [user, setUser] = useState(null)

    useEffect(() => {
        const updateUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                setUser(user)
            } catch {
                setUser(null)
            }
        }
        Hub.listen('auth', updateUser) // listen for login/signup events
        updateUser() // check manually the first time because we won't get a Hub event
        return () => Hub.remove('auth', updateUser) // cleanup
    }, []);

    return user ? (
        <AuthContext.Provider value={user}>
            <AuthenticatedStackScreen/>
        </AuthContext.Provider>
    ) : (
        <UnauthenticatedStackScreen/>
    )
}