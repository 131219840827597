import React, {Component} from "react";
import {View} from "react-native";
import Toast from "../components/Toast";
import ActionSheet from "../components/ActionSheet";
import PropTypes from 'prop-types';

class Root extends Component {
    render() {
        return (
            <View ref={c => (this._root = c)} {...this.props} style={{ flex: 1 }}>
                {this.props.children}
                <Toast
                    ref={c => {
                        if (c) Toast.toastInstance = c;
                    }}
                />
                <ActionSheet
                    ref={c => {
                        if (c) ActionSheet.actionSheetInstance = c;
                    }}
                />
            </View>
        );
    }
}

Root.propTypes = {
  children: PropTypes.object.isRequired,
};


export default Root;