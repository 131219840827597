import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {StatusBar} from 'expo-status-bar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Amplify, {graphqlOperation, API, Auth} from 'aws-amplify';
import {createEvent} from "../graphql/mutations";
import awsconfig from '../../aws-exports';
import Styles from "../styles/Global";
import TextInputWithButton from "../components/inputs/TextInputWithButton";
import {formatPhoneNumber} from '../helpers/phoneNumber'
import {phoneNumberValidator} from "../validators/phoneNumber";
import CommonColors from "../styles/CommonColors";
import {v4 as uuid} from 'uuid';
import HeaderLogo from "../../assets/svgs/HeaderLogo";

Amplify.configure(awsconfig);

const DEFAULT_MSG = 'Please enter your phone number'
const VERIFY_NUMBER = 'Verifying number...';
const USER_NOT_FOUND = 'User not found';
const WAITING_FOR_ACCESS_CODE = 'Check your text messages for an access code';

export default function SignInScreen() {
    const [message, setMessage] = useState<string>(DEFAULT_MSG);
    const [messageSpinner, setMessageSpinner] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
    const [accessCode, setAccessCode] = useState('');
    const [number, setNumber] = useState<string>('');
    // const password = Math.random().toString(10) + 'Abc#';

    const signIn = () => {
        const phoneNumber = phoneNumberValidator(number)

        if (phoneNumber.isValid) {
            setErrorMessage(null)
            setMessage(VERIFY_NUMBER)
            setMessageSpinner(true)

            Auth.signIn(phoneNumber.value || '')
                .then((result) => {
                    setSession(result);
                    setMessage(WAITING_FOR_ACCESS_CODE);
                    setMessageSpinner(false)
                })
                .catch((e) => {
                    if (e.code === 'UserNotFoundException') {
                        setMessage(DEFAULT_MSG)
                        setErrorMessage(USER_NOT_FOUND)
                        setMessageSpinner(false)
                        //signUp()
                    } else if (e.code === 'UsernameExistsException') {
                        console.log(e.code);
                        console.error(e);
                        // signIn();
                    } else {
                        console.log(e.code);
                        console.error(e);
                    }
                });
        } else {
            setErrorMessage(phoneNumber.errorMsg || null);
        }
    };

    const verifyAccessCode = () => {
        Auth.sendCustomChallengeAnswer(session, accessCode)
            .then((user) => {
                const input = {
                    input: {
                        userID: user.username,
                        eventID: uuid(),
                        eventName: 'user-signed-in',
                        metadata: JSON.stringify({
                            description: 'User successfully verified their access code'
                        }),
                        createdAt: new Date().toISOString()
                    }
                }
                API.graphql(graphqlOperation(createEvent, input))
            })
            .catch((err) => {
                setErrorMessage(err.message);
                setAccessCode('');
                console.log(err);
            });
    };

    // const signUp = async () => {
    //     const result = await Auth.signUp({
    //         username: number,
    //         password,
    //         attributes: {
    //             phone_number: number,
    //         },
    //     }).then(() => signIn());
    //     return result;
    // };

    const {background, content, errorMsgText, flex, h1, h5, mbMedium, msgText, tCenter, trueCenter} = Styles

    return (
        <SafeAreaView
            style={background}
        >
            <StatusBar style="light"/>
            <View style={content}>
                <View style={[flex, trueCenter]}>
                    <HeaderLogo width="80%" height="100%" />
                </View>

                {message && (
                    <View style={mbMedium}>
                        <Text style={[h5, msgText, tCenter]}>{message}</Text>
                    </View>
                )}

                {messageSpinner && (
                    <View style={mbMedium}>
                        <ActivityIndicator size="small" color={CommonColors.green}/>
                    </View>
                )}

                {errorMessage && (
                    <View style={mbMedium}>
                        <Text style={[h5, errorMsgText, tCenter]}>{errorMessage}</Text>
                    </View>
                )}

                {!user && !session && (
                    <View style={flex}>
                        <View style={{height: 50}}>
                            <TextInputWithButton
                            	inputStyle={{width: '100%'}}
                                buttonText={'Get Access Code'}
                                onChangeText={(phoneNumber) => setNumber(formatPhoneNumber(phoneNumber))}
                                placeholder={'Phone Number'}
                                onPress={signIn}
                                value={number}
                            />
                        </View>
                    </View>
                )}

                {!user && session && (
                    <View style={flex}>
                        <View style={{height: 50}}>
                            <TextInputWithButton
                            	inputStyle={{width: '100%'}}
                                buttonText={'Verify Access Code'}
                                onChangeText={setAccessCode}
                                placeholder={'Access Code'}
                                onPress={verifyAccessCode}
                                value={accessCode}
                            />
                        </View>
                    </View>
                )}


                {/*{!user && !session && (*/}
                {/*    <View>*/}
                {/*        <TextInput*/}
                {/*            placeholder={'Phone number'}*/}
                {/*            onChangeText={phoneNumber => setNumber(phoneNumber)}*/}
                {/*        />*/}
                {/*        <TouchableOpacity*/}
                {/*            onPress={signIn}*/}
                {/*        >*/}
                {/*            <Text>Get OTP</Text>*/}
                {/*        </TouchableOpacity>*/}
                {/*    </View>*/}
                {/*)}*/}

                {/*{!user && session && (*/}
                {/*    <View>*/}
                {/*        <TextInput*/}
                {/*            placeholder={'Your OTP'}*/}
                {/*            onChangeText={otp => setOtp(otp)}*/}
                {/*        />*/}
                {/*        <TouchableOpacity*/}
                {/*            onPress={verifyOtp}*/}
                {/*        >*/}
                {/*            <Text>Confirm</Text>*/}
                {/*        </TouchableOpacity>*/}
                {/*    </View>*/}
                {/*)}*/}

                {/*<TouchableOpacity*/}
                {/*    onPress={verifyAuth}*/}
                {/*>*/}
                {/*    <Text>Am I signed in?</Text>*/}
                {/*</TouchableOpacity>*/}
            </View>
        </SafeAreaView>
    );
}
