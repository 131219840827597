import React from "react";
// import {SvgXml} from "react-native-svg";
import { SvgXml } from '@cantoo/rn-svg';

export default function MoreVertical(props) {

    const more = `<svg height='100px' width='100px'  fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
        <path d="M52.8,33.1c-2.7,0.7-5.3,0.4-7.8-1c-0.4-0.2-0.8-0.5-1.1-0.7c-1.8-1.4-3.1-3.2-3.7-5.5c-0.7-2.7-0.4-5.3,1-7.8  c1.4-2.5,3.5-4.1,6.2-4.8c2.7-0.7,5.3-0.4,7.8,1c2.5,1.4,4.1,3.5,4.8,6.3c0.7,2.7,0.4,5.3-1,7.8C57.6,30.8,55.5,32.4,52.8,33.1  L52.8,33.1z"></path>
        <path d="M52.6,63.5c-2.7,0.7-5.3,0.4-7.8-1c-0.4-0.2-0.8-0.5-1.1-0.7c-1.8-1.4-3.1-3.2-3.7-5.5c-0.7-2.7-0.4-5.3,1-7.8  c1.4-2.5,3.5-4.1,6.2-4.8c2.7-0.7,5.3-0.4,7.8,1c2.5,1.4,4.1,3.5,4.8,6.3c0.7,2.7,0.4,5.3-1,7.8C57.4,61.2,55.3,62.8,52.6,63.5  L52.6,63.5z"></path>
        <path d="M52.6,93.9c-2.7,0.7-5.3,0.4-7.8-1c-0.4-0.2-0.8-0.5-1.1-0.7c-1.8-1.4-3.1-3.2-3.7-5.5c-0.7-2.7-0.4-5.3,1-7.8  c1.4-2.5,3.5-4.1,6.2-4.8c2.7-0.7,5.3-0.4,7.8,1c2.5,1.4,4.1,3.5,4.8,6.3c0.7,2.7,0.4,5.3-1,7.8C57.4,91.6,55.3,93.2,52.6,93.9  L52.6,93.9z"></path>
    </svg>`

    const MoreVertical = () => <SvgXml xml={more} width={props.width} height={props.height}/>;

    return <MoreVertical/>
}