import React from 'react';
import {createStackNavigator} from "@react-navigation/stack";
import SignInScreen from "../screens/SignIn";
import {NavigationContainer} from "@react-navigation/native";

const UnauthenticatedStack = createStackNavigator();

export default function UnauthenticatedStackScreen() {
    return (
        <NavigationContainer>
            <UnauthenticatedStack.Navigator screenOptions={{headerShown: false}}>
                <UnauthenticatedStack.Screen name="SignIn" component={SignInScreen} options={{headerShown: false}}/>
            </UnauthenticatedStack.Navigator>
        </NavigationContainer>
    );
}
