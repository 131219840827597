import React from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function Anxiety() {

    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        contentView,
        headerImageView,
        headerText,
        headerView,
        subHeaderText,
        sectionText,
        unorderedListBullet,
        unorderedListText,
        unorderedListView
    } = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/anxiety.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>Anxiety: What to look for</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionText, mtSmall]}>Did you know that anxiety is not only normal, it’s also quite
                        useful? Research has shown that some anxiety can help us learn how to keep bad things from
                        happening. Anxiety can also help us stay alert and prepared to act if the need arises. Even
                        the most dominant athletes admit feeling a little anxious before a big game.</Text>
                    <Text style={[sectionText, mtSmall]}>But like depression, we can separate healthy anxiety from unhealthy
                        anxiety. It starts to become unhealthy when our anxiety becomes stronger, more frequent,
                        and lasts longer than usual. Sometimes our anxiety is accompanied with worry and
                        fear that we can't control. Too much anxiety can prevent us from living the life we want and get in the way of our
                        day-to-day activities.</Text>
                    <Text style={[sectionText, mtSmall]}>Like depression, we can sometimes recognize symptoms of anxiety
                        in ourselves. It isn’t hard to notice when we spend entire days worrying about a hundred
                        different things. Sometimes we can spend all our time worrying about one or two things. Other
                        times we might hear from our family members and friends that they’ve noticed changes in us. They
                        might say that we’ve been quick to lash out or that they’ve been walking on eggshells in our
                        presence. Overcoming anxiety can feel like an impossible task, but you’re not alone. The first
                        step is to understand our symptoms. Here are some common anxiety symptoms to look out
                        for:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Excessive and uncontrollable anxiety, fear, or
                            worry</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Restlessness or a feeling of being
                            "on-edge"</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Irritability or angry outbursts</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Trouble concentrating or focusing (not due to
                            medication)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Constant muscle tension or tightness</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Trembling or shaking (not due to
                            medication)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Dry mouth (not due to medication)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Panic attacks that come suddenly and "out of the
                            blue"</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Sleep difficulties (can’t fall asleep or stay
                            asleep)</Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}