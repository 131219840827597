import React from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function Prevalence() {

    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        accentSectionText,
        contentView,
        headerImageView,
        headerText,
        headerView,
        sectionHeader,
        sectionText,
        subHeaderText,
    } = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/prevalence.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>Depression and Anxiety: Occurrence, Causes, and Impact</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionHeader, mtLarge]}>Occurrence</Text>
                    <Text style={[sectionText]}>If you’ve been struggling with depression or anxiety, you’re in
                        good company. Depression and anxiety are the two most common mental disorders that impact people
                        from all walks of life, in every country around the world. In the U.S., about 40 million adults
                        (about 1 in 5 adults) are affected by anxiety. Around 17 million adults in the U.S.
                        (about 1 in 15 adults) have experienced a depressive episode in the last year.
                        Research shows that rates of depression and anxiety are even higher among people with a chronic
                        disease, like cancer. For example, depression and anxiety impact almost 40% of all adults
                        diagnosed with cancer.</Text>

                    <Text style={[sectionHeader, mtLarge]}>Causes</Text>
                    <Text style={[sectionText]}>There is no single cause for depression or anxiety. In fact,
                        depression and anxiety can occur for a variety of reasons. Below are some of these reasons. As
                        you read each one, remember that hope is not lost. There are a number of things you can do to
                        feel better.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Family History</Text>
                    <Text style={[sectionText, mtSmall]}>It is common to have a family history of depression or anxiety.
                        Many of us have a parent, uncle, cousin, or sibling that struggled with depression or anxiety at
                        some point. Research shows that the genes passed down to us can make us susceptible to
                        developing depression and anxiety. Just because a close family member had depression or anxiety
                        doesn't mean you’ll get it, but it could increase your chances of developing it later on.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Stressful Life Events</Text>
                    <Text style={[sectionText, mtSmall]}>Losing a loved one, your job, or a receiving a serious
                        medical diagnosis can be risk factors for depression and anxiety. Sometimes an event can have
                        such an impact on us that it causes long-term mental trauma. But stressful life events don’t
                        always involve a single event. Sometimes a stressor can span over a long period of time, like
                        being in an unhealthy relationship or dealing with long-term treatment for an illness.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Patterns of Thinking</Text>
                    <Text style={[sectionText, mtSmall]}>We each see the world through a unique lens. Some people see
                        the glass as half-full, while others focus on the part that is half-empty. Research shows that
                        how we interpret information or how we talk to ourselves can have a big impact on our mood.
                        Focusing on the negatives and
                        analyzing painful memories from our past can flatten our mood and worsen depression and anxiety
                        symptoms.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Drug and Alcohol Abuse</Text>
                    <Text style={[sectionText, mtSmall]}>It can be tempting to use drugs or alcohol to cope with life’s
                        stressors. But using drugs and alcohol is not a solution, it is part of the problem. Studies
                        show that abusing drugs and alcohol worsens depression and anxiety symptoms.</Text>

                    <Text style={[sectionHeader, mtLarge]}>Impact</Text>
                    <Text style={[sectionText]}>Regardless of the cause, depression and anxiety can lead to isolation,
                        loneliness, and conflict with our loved ones. Symptoms can stop us in our tracks and make it
                        hard to do the bare minimum that is needed to just get by. But there is one thing that all
                        people with depression and anxiety have in common: they want to get better.</Text>

                </View>
            </ScrollView>
        </SafeAreaView>
    )
}