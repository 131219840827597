import React from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function Depression() {

    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        contentView,
        headerImageView,
        headerText,
        headerView,
        subHeaderText,
        sectionText,
        unorderedListBullet,
        unorderedListText,
        unorderedListView
    } = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/depression.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>Depression: What to look for</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionText, mtSmall]}>Everyone feels sad, angry, or down from time to time. These are
                        common emotions that people feel on a regular basis. But we should start to pay attention if
                        these emotions become stronger, more common, and last longer than they normally do. When that
                        happens, we might find that these emotions keep us from carrying out our normal activities.
                        These emotions might prevent us from living the life we want to live.</Text>
                    <Text style={[sectionText, mtSmall]}>We can sometimes recognize symptoms of depression in ourselves.
                        We might notice that we’re running low on motivation, or that we’re more withdrawn from our
                        loved ones. Other symptoms are hard to see in ourselves. We might hear a family member or a
                        friend ask us if we’re feeling OK. They might see that we’ve been more irritable lately, or that
                        we’re avoiding eye contact. It can be hard to admit that we’re struggling, but if you are
                        struggling you’re not alone.
                        The first step is to identify what symptoms we have and when they started. Here are some common
                        depression symptoms to look out for:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Feelings of sadness or hopelessness most of the
                            day, nearly every day for at least 2 weeks</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Little interest or pleasure in doing
                            things</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Significant weight loss (when not dieting) or
                            weight gain </Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Feeling tired or having little energy (not due to
                            medication) </Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Moving or talking more slowly than usual, or
                            feeling so restless you can’t stay still</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Inability to concentrate, remember important
                            things, or make decisions</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Changes to sleep (can’t fall asleep, can’t stay
                            asleep, or oversleeping)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Feelings of guilt, helplessness, or
                            worthlessness </Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Having low self-esteem, avoiding eye contact or
                            face-to-face interactions</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Frequent thoughts or attempts to hurt yourself or
                            others (seek help immediately)</Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}