import React from 'react';
import {createStackNavigator} from "@react-navigation/stack";
import {NavigationContainer} from "@react-navigation/native";
import MainScreen from "../screens/Main";
import WhatIsDistressScreen from "../screens/topics/WhatIsDistress";
import DepressionScreen from "../screens/topics/Depression";
import AnxietyScreen from "../screens/topics/Anxiety";
import PrevalenceScreen from "../screens/topics/Prevalence";
import DosAndDontsScreen from "../screens/topics/DosAndDonts";
import StrategiesScreen from "../screens/topics/Strategies";
import HelpScreen from "../screens/topics/Help";
import AboutAppScreen from "../screens/topics/AboutApp";
import AppBar from "../components/AppBar";

const AuthenticatedStack = createStackNavigator();

export default function AuthenticatedStackScreen() {
    return (
        <NavigationContainer>
            <AuthenticatedStack.Navigator mode="modal" screenOptions={AppBar}>
                <AuthenticatedStack.Screen name="Main" component={MainScreen}/>
                <AuthenticatedStack.Screen name="WhatIsDistress" component={WhatIsDistressScreen}/>
                <AuthenticatedStack.Screen name="Depression" component={DepressionScreen}/>
                <AuthenticatedStack.Screen name="Anxiety" component={AnxietyScreen}/>
                <AuthenticatedStack.Screen name="Prevalence" component={PrevalenceScreen}/>
                <AuthenticatedStack.Screen name="DosAndDonts" component={DosAndDontsScreen}/>
                <AuthenticatedStack.Screen name="Strategies" component={StrategiesScreen}/>
                <AuthenticatedStack.Screen name="Help" component={HelpScreen}/>
                <AuthenticatedStack.Screen name="AboutApp" component={AboutAppScreen}/>
            </AuthenticatedStack.Navigator>
        </NavigationContainer>
    );
}
