import React from "react";
import {Animated, Platform, StyleSheet, Text, View} from "react-native";
import CommonColors from "../styles/CommonColors";

class Toast extends React.Component {
    static show({ ...config }) {
        this.toastInstance.showToast({ config })
    }

    static toastInstance;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fadeAnim: new Animated.Value(0)
        }
    }

    showToast({ config }) {
        this.setState({
            visible: true,
            text: config.text,
            toastColor: {...styles.toastColor, ...config.toastColor},
            toastStyles: {...styles.toastStyles, ...config.toastStyles},
            textStyle: {...styles.text, ...config.textStyle},
            onClose: config.onClose
        });

        // If we have a toast already open, cut off its close timeout so that it won't affect *this* toast.
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }

        // Set the toast to close after the duration.
        if (config.duration !== 0) {
            const duration = config.duration > 0 ? config.duration : 2000;
            this.closeTimeout = setTimeout(
                this.closeToast.bind(this, 'timeout'),
                duration
            );
        }

        // Fade the toast in now.
        Animated.timing(this.state.fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false
        }).start();
    }

    closeContainer(reason) {
        this.setState({
            visible: false
        });
        const { onClose } = this.state;
        if (onClose && typeof onClose === 'function') {
            onClose(reason);
        }
    }

    closeToast(reason) {
        clearTimeout(this.closeTimeout);
        Animated.timing(this.state.fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: false
        }).start(() => {
            this.closeContainer.bind(this, reason);
        });
    }

    render() {
        if(this.state.visible) {
            return (
                <View
                    style={styles.container}
                    pointerEvents="none"
                >
                    <Animated.View style={[this.state.toastStyles, this.state.toastColor, {opacity: this.state.fadeAnim}]}>
                        <Text style={this.state.textStyle}>
                            {this.state.text}
                        </Text>
                    </Animated.View>
                </View>
            );
        } else {
            return null;
        }
    }
}

export default Toast;

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },
    toastStyles: {
        height: 50,
        minWidth: '60%',
        justifyContent: 'center',
        alignSelf: 'center',
        position: 'absolute',
        bottom: Platform.OS === 'android' ? 8 : 25,
        borderRadius: 8,
        paddingHorizontal: 16,
        elevation: 9
    },
    toastColor: {
        backgroundColor: CommonColors.green
    },
    text: {
        color: CommonColors.white,
        fontFamily: "SFProDisplaySemibold",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: "normal",
        textAlign: "center",
        lineHeight: 30,
        backgroundColor: "transparent",
    }
});
