import React from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function WhatIsDistress() {

    const {background, flex, mtSmall} = Styles
    const {contentView, headerImageView, headerText, headerView, subHeaderText, sectionText} = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/what-is-distress.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>What is distress?</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionText, mtSmall]}>We all get stressed at times, it’s part of being human. Stress
                        is a normal
                        response to life’s challenges. Being stressed is so ingrained in our society that many of us
                        embrace it as a badge of honor. Research shows that having some stress is healthy because it
                        calls us to take action. But here’s also a downside to stress.</Text>
                    <Text style={[sectionText, mtSmall]}>When our stress starts to overwhelm us, we can quickly become
                        distressed. Distress is what happens to us when we experience high levels of stress on a daily
                        basis. Over time, distress can impact the way we think, feel, and act. You may have trouble
                        making decisions and thinking clearly. You may feel angry, afraid, alone, or out of control.
                        Some of the most common symptoms of distress are feeling depressed and anxious. Fortunately, there
                        are ways we can prevent distress from getting in the way of our personal happiness, our
                        relationships, and realizing the quality of life we deserve. Read through the other topics in this app to learn
                        more about depression and anxiety and how to manage them.</Text>
                </View>

            </ScrollView>
        </SafeAreaView>
    )
}