import React, {useContext} from "react";
import {Platform, TouchableOpacity, View, Image, StyleSheet} from "react-native";
import MoreVerticalSVG from "../../assets/svgs/MoreVertical";
import MoreHorizontalSVG from "../../assets/svgs/MoreHorizontal";
import ActionSheet from "./ActionSheet";
import CommonColors from "../styles/CommonColors";
import {API, Auth, graphqlOperation} from "aws-amplify";
import HeaderLogoSVG from "../../assets/svgs/HeaderLogo";
import {createEvent} from "../graphql/mutations";
import {v4 as uuid} from 'uuid';
import {AuthContext} from "../contexts/AuthContext";

export default function AppBar({navigation}) {

    const user = useContext(AuthContext)

	const backStyle = StyleSheet.create({
	  back: {
		width: 24,
		height: 24,
	  },
	});

    return {
        // headerTitle: <HeaderLogoSVG width={180} height="100%"/>,
        headerTitle: <HeaderLogoSVG width={180} height={30}/>,
        headerTitleAlign: 'center',
        headerBackTitle: null,
        headerTintColor: '#ffffff',
    	headerBackImage: () => (<Image source={require('../../assets/back_chevron.png')} style={backStyle.back} />),
        headerStyle: {
            backgroundColor: "#000000"
        },
        headerRight: () =>
            <View style={{flexDirection: 'row'}}>
                <TouchableOpacity
                    onPress={() =>
                        ActionSheet.show(
                            {
                                items: ["About this App", "Sign out", {
                                    text: "Cancel",
                                    textColor: CommonColors.blue
                                }]
                            },
                            buttonIndex => {
                                switch (buttonIndex) {
                                    case 0: {
                                        const input0 = {
                                            input: {
                                                userID: user.username,
                                                eventID: uuid(),
                                                eventName: 'topic-visited',
                                                metadata: JSON.stringify({
                                                    description: 'User pressed button from AppBar to go to topic',
                                                    topic: 'About this App'
                                                }),
                                                createdAt: new Date().toISOString()
                                            }
                                        }
                                        API.graphql(graphqlOperation(createEvent, input0))
                                        navigation.navigate('AboutApp')
                                        break;
                                        }
                                    case 1: {
                                        const input1 = {
                                            input: {
                                                userID: user.username,
                                                eventID: uuid(),
                                                eventName: 'user-signed-out',
                                                metadata: JSON.stringify({
                                                    description: 'User signed out of the app'
                                                }),
                                                createdAt: new Date().toISOString()
                                            }
                                        }
                                        API.graphql(graphqlOperation(createEvent, input1))
                                        Auth.signOut().catch((err) => console.log(err));
                                        break;
                                        }
                                }
                            }
                        )}
                >
                    <View style={{marginRight: 17}}>
                        {
                            Platform.OS === 'android' ?
                                <MoreVerticalSVG width={20} height={20}/>
                                :
                                <MoreHorizontalSVG width={20} height={20}/>
                        }
                    </View>
                </TouchableOpacity>
            </View>
    }
}