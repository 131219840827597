import React from "react";
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {TextInputWithButtonProps} from "../../types/components";
import Styles from "../../styles/Global";
import CommonColors from "../../styles/CommonColors";
import normalize from "react-native-normalize";

const TextInputWithButton = ({buttonText, inputStyle, onChangeText, onPress, placeholder, value}: TextInputWithButtonProps) => {
    return (
        <View style={Styles.flexRow}>
            <TextInput
                placeholder={placeholder}
                placeholderTextColor={CommonColors.gray}
                style={[styles.textInputWithButtonInput, inputStyle]}
                keyboardType='numeric'
                onChangeText={text => onChangeText(text)}
                value={value}
            />
            <TouchableOpacity
                onPress={() => onPress()}
                style={styles.inputAdjacentButton}
            >
                <Text style={styles.buttonText}>
                    {buttonText}
                </Text>
            </TouchableOpacity>
        </View>
    );
}

export default TextInputWithButton;

const styles = StyleSheet.create({
    buttonText: {
        color: CommonColors.white,
        fontSize: normalize(16),
        fontFamily: 'SFProDisplayBold',
    },
    inputAdjacentButton: {
        backgroundColor: CommonColors.purple,
        paddingVertical: 6,
        paddingHorizontal: 12,
        borderColor: CommonColors.gray,
        borderWidth: 2,
        borderLeftWidth: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        justifyContent: 'center'
    },
    textInputWithButtonInput: {
        flexGrow: 1,
        backgroundColor: '#12141b',
        color: '#FFFFFF',
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderColor: '#666666',
        borderWidth: 2,
        fontSize: normalize(20),
        fontFamily: 'SFProDisplayRegular',
        textAlign: 'center',
        paddingVertical: 4
    }
})