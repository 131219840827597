import CommonColors from "./CommonColors";
import normalize from 'react-native-normalize';
import {StyleType} from "../types/styles";

const Style : StyleType = {
    background: {
        marginTop: -14,
        flex: 1,
        backgroundColor: CommonColors.darkBlue
    },
    topicPage: {
        marginTop: -36
    },
    content: {
        flex: 1,
        padding: 16
    },
    msgText: {
        color: CommonColors.green
    },
    errorMsgText: {
        color: CommonColors.red
    },
    h1: {
        color: CommonColors.white,
        fontSize: normalize(30),
        lineHeight: 34,
        fontFamily: 'SFProDisplayBold'
    },
    h2: {
        color: CommonColors.white,
        fontSize: normalize(24),
        lineHeight: normalize(28, 'height'),
        fontFamily: 'SFProDisplayLight'
    },
    h3: {
        color: CommonColors.white,
        fontSize: normalize(18),
        lineHeight: normalize(22, 'height'),
        fontFamily: 'SFProDisplayBold'
    },
    h4: {
        color: CommonColors.white,
        fontSize: normalize(16),
        lineHeight: normalize(20, 'height'),
        fontFamily: 'SFProDisplaySemibold'
    },
    h5: {
        color: CommonColors.white,
        fontSize: normalize(14),
        lineHeight: normalize(18, 'height'),
        fontFamily: 'SFProDisplayRegular'
    },
    h6: {
        color: CommonColors.white,
        fontSize: normalize(12),
        lineHeight: normalize(16, 'height'),
        fontFamily: 'SFProDisplayBold'
    },
    bodyText: {
        color: CommonColors.white,
        fontSize: normalize(16),
        lineHeight: normalize(22, 'height'),
        fontFamily: 'SFProDisplayRegular'
    },

    //
    // Buttons
    //

    outlineButton: {
        backgroundColor: 'transparent',
        borderRadius: 8,
        borderColor: CommonColors.green,
        borderWidth: 2,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 16,
        alignSelf: "center"
    },
    outlineButtonText: {
        color: CommonColors.green,
        fontSize: normalize(14),
        fontFamily: 'SFProDisplayRegular',
        fontStyle: "normal",
        fontWeight: "normal",
        paddingHorizontal: 16,
        paddingVertical: 8
    },
    largeOutlineButton: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: 8,
        borderColor: CommonColors.green,
        borderWidth: 2,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 16,
        alignSelf: "center"
    },
    largeOutlineButtonText: {
        color: CommonColors.green,
        fontSize: normalize(16),
        fontFamily: 'SFProDisplayRegular',
        fontStyle: "normal",
        fontWeight: "normal",
        paddingHorizontal: 16,
        paddingVertical: 8
    },
    smallButton: {
        backgroundColor: CommonColors.blue,
        borderRadius: 16,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        alignSelf: "center",
        paddingLeft: 16,
        paddingRight: 16,
        height: 28,
        marginBottom: 23
    },
    smallButtonText: {
        color: "#FFFFFF",
        fontSize: 14,
        fontFamily: 'SFProDisplayRegular',
        fontStyle: "normal",
        fontWeight: "normal",
        textAlign: "center",
    },

    //
    // Utility
    //

    mtSmall: {
        marginTop: 8
    },
    mtMedium: {
        marginTop: 16
    },
    mtLarge: {
        marginTop: 32
    },
    mtXLarge: {
        marginTop: 64
    },
    mbSmall: {
        marginBottom: 8
    },
    mbMedium: {
        marginBottom: 16
    },
    mbLarge: {
        marginBottom: 32
    },
    mlSmall: {
        marginTop: 8
    },
    mlMedium: {
        marginTop: 16
    },
    mlLarge: {
        marginTop: 32
    },
    w100: {
        width: '100%'
    },
    flex: {
        flex: 1
    },
    flexRow: {
        flex: 1,
        flexDirection: 'row'
    },

    //
    // Positioning
    //

    tCenter: {
        textAlign: 'center'
    },
    trueCenter: {
        alignItems: 'center',
        justifyContent: 'center'
    },

    //
    // SVGs
    //

    gridBGView: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        height: 300,
        width: '100%'
    },

    //
    // Debugging
    //

    bRed: {
        borderColor: 'red',
        borderWidth: 1
    },
    bGreen: {
        borderColor: 'green',
        borderWidth: 1
    },
    bBlue: {
        borderColor: 'blue',
        borderWidth: 1
    }
}

export default Style