import React from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function Strategies() {

    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        accentSectionText,
        contentView,
        headerImageView,
        headerText,
        headerView,
        sectionHeader,
        sectionText,
        subHeaderText,
        unorderedListBullet,
        unorderedListText,
        unorderedListView
    } = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/strategies.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>Managing depression and anxiety: Strategies</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionHeader, mtLarge]}>Deep Breathing</Text>
                    <Text style={[sectionText]}>Breathing is one of the most natural things you do. But did you know
                        that how you breathe can impact your thoughts and feelings? When we breathe quickly and through
                        our chest, it activates our sympathetic nervous system, the “fight or flight” response. Let’s
                        learn about another way to breathe that can actually help us feel calmer. Here are the
                        steps:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>1.</Text>
                        <Text style={[sectionText, unorderedListText]}>Sit or stand straight so your body and neck
                            aren't slumped forward</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>2.</Text>
                        <Text style={[sectionText, unorderedListText]}>Pay attention to the muscles in your body,
                            making sure to relax ones that are tight</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>3.</Text>
                        <Text style={[sectionText, unorderedListText]}>Inhale through your nose 3 seconds, counting
                            1-2-3 slowly in your mind. As you inhale you should feel like your stomach is filling with
                            air.</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>4.</Text>
                        <Text style={[sectionText, unorderedListText]}>Hold for a second, then slowly exhale through
                            your mouth for 3 seconds, counting 1-2-3 in your mind.</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>5.</Text>
                        <Text style={[sectionText, unorderedListText]}>Practice this method of inhaling and exhaling for
                            2 minutes at a time. Your focus should be on breathing naturally and deeply.</Text>
                    </View>

                    <Text style={[sectionHeader, mtLarge]}>Social support</Text>
                    <Text style={[sectionText]}>Social support is the help we get from people in our lives. Family
                        members, friends, coworkers, or even a pet can be a valuable source of support. The support we
                        receive can come in many forms: financial assistance, emotional support, advice, even practical
                        support like someone helping us with a task. Research shows that being connected to others is
                        important for our own well-being. When we’re stressed or down, we can actually let others share
                        in the burden to lift us up. In order to make the best use of your social support network, it
                        helps to make a list of people in your network and what they can offer. Some people are really
                        handy in a pinch but are maybe not the best listeners. Some people give great advice, but that’s
                        not always helpful when we just need someone to listen. Knowing who you can turn to for
                        different needs can help you build and maintain a solid social support network.</Text>

                    <Text style={[sectionHeader, mtLarge]}>Identifying and Untwisting Negative Thoughts</Text>
                    <Text style={[sectionText]}>Our patterns of thinking can become so natural that we take them for
                        granted. Many of us develop patterns of thinking that are overly negative, pessimistic, and not
                        grounded in reality. This is an issue because how we think has an impact on how we feel. So what
                        can we do to challenge our thinking patterns? There are 2 steps:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>1.</Text>
                        <Text style={[sectionText, unorderedListText]}>Identify the unhelpful or negative thought</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>2.</Text>
                        <Text style={[sectionText, unorderedListText]}>Untwist the thought</Text>
                    </View>

                    <Text style={[sectionText]}>Identifying thoughts begins with writing the thought down. Some commons
                        ones are "I'm a complete failure" and "I'll never be happy again." Here are some of the most
                        common types of unhelpful thoughts:</Text>

                    <Text style={[accentSectionText, mtLarge]}>All-or-nothing thinking</Text>
                    <Text style={[sectionText, mtSmall]}>Your thought is black-and-white, you're either destined for
                        success or doomed to failure, or that the people in your life are either good or bad.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Overgeneralizing</Text>
                    <Text style={[sectionText, mtSmall]}>When we overgeneralize, we assume that one thing will carry
                        over to everything across the board. For example, you might have had a negative experience in
                        one relationship and assume you just aren’t good at relationships at all.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Catastrophizing</Text>
                    <Text style={[sectionText, mtSmall]}>Sometimes we assume that a small setback will eventually
                        snowball into a problem we can’t overcome. Ordinary worries can quickly escalate into imagining
                        the worst possible outcome.</Text>

                    <Text style={[accentSectionText, mtLarge]}>Labeling</Text>
                    <Text style={[sectionText, mtSmall]}>It can be easy to let a lone event define ourselves entirely.
                        We might experience a minor setback yet be quick to label ourselves a "failure." </Text>

                    <Text style={[accentSectionText, mtLarge]}>Mind Reading</Text>
                    <Text style={[sectionText]}>It can be tempting to assume that we know what others are thinking. When
                        we assume that others don’t want to get together with us, it can prevent us from ever
                        asking.</Text>

                    <Text style={[sectionText]}>How can we manage these unhelpful thoughts? Fortunately, there are ways
                        to challenge the thoughts that add to our suffering. Sometimes just noticing them is enough to
                        stop them from impacting us. Other times, it helps to ask questions that we can use to come up
                        with more objective, and helpful, thoughts. When you notice that you’re having an unhelpful
                        thought, ask the following:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Am I seeing the shades of gray that lie between
                            the extremes?</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Is there any proof I have that this thought isn’t
                            true?</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>What would I tell a friend in a similar
                            situation?</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Am I making a big deal out of something small?</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Am I underestimating my own strengths or my mental
                            toughness? Have I overcome similar situations?</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>What are the actual chances my thought comes
                            true? If it did come true, would I be able to cope?</Text>
                    </View>

                    <Text style={[sectionText]}>It can be hard to see when our thoughts are holding us back. We forget
                        that our thoughts aren't facts. By catching unhelpful thoughts when they occur, we can practice
                        challenging them and train ourselves to react in a different way.</Text>

                    <Text style={[sectionHeader, mtLarge]}>Practice Self-Care</Text>
                    <Text style={[sectionText]}>Life can be so chaotic that we forget to take care of ourselves. Other
                        times, taking care of ourselves seems like a luxury we can’t afford. If you’ve ever been on
                        an airplane, you may remember that the pre-flight safety video always tells passengers to put on
                        their oxygen mask first before assisting others. The truth is, if we don’t take care of
                        ourselves we probably aren't in best position to take care of others. Finding the time and
                        energy to practice self-care can be challenging when we’re feeling stressed. Self-care doesn’t
                        have to be going to the spa for a day or taking a fancy overseas trip. There are a lot of little
                        things we can do to start the process of self-care, like drinking enough water, getting enough
                        sleep, and brushing our teeth. Once you get the hang of doing the little things, make a list of
                        things that give you a sense of joy and accomplishment. Some common activities are exercising,
                        reading, spending time with loved ones, and being in nature. Make an effort to do at least one
                        thing on your list each week, then slowly ramp up until you’re able to do something almost every
                        day.</Text>

                </View>
            </ScrollView>
        </SafeAreaView>
    )
}