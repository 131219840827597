export default {
    blue: '#4A90E2',
    blueDisabled: 'rgba(74, 144, 226, 0.25)',
    mediumBlue: '#081A34',
    darkBlue: '#00030B',
    green: '#64F585',
    greenDisabled: '#498a58',
    purple: '#232D4B',
    red: '#E11D59',
    white: '#FFFFFF',
    gray: '#666666'
}