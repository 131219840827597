import {StyleSheet} from "react-native";
import normalize from "react-native-normalize";

export default StyleSheet.create({
    backgroundView: {
        backgroundColor: "#00030B",
        flex: 1,
        flexDirection: 'column'
    },
    headerImageView: {
        width: '100%',
        height: 277,
        resizeMode: 'contain',
        borderColor: '#666666',
        borderBottomWidth: 1,
        opacity: 0.6
    },
    headerView: {
        marginLeft: 16,
        marginRight: 16,
        marginTop: 16,
        borderColor: '#666666',
        borderBottomWidth: 1,
        paddingBottom: 10
    },
    subHeaderText: {
        color: "#64F585",
        fontFamily: "SFProDisplayBold",
        fontSize: normalize(16),
        marginBottom: 8
    },
    headerText: {
        color: "#FFFFFF",
        fontFamily: "SFProDisplayBold",
        fontSize: normalize(40),
        lineHeight: normalize(40)
    },
    contentView: {
        marginLeft: 16,
        marginRight: 16,
        paddingBottom: 32
    },
    sectionHeader: {
        color: "#FFFFFF",
        fontFamily: "SFProDisplayBold",
        fontSize: normalize(26),
        lineHeight: normalize(26),
        marginTop: 32,
        marginBottom: 10
    },
    sectionText: {
        color: "#FFFFFF",
        fontFamily: "SFProDisplayLight",
        fontSize: normalize(18),
        lineHeight: normalize(23)
    },
    sectionTextBold: {
        fontFamily: "SFProDisplayBold",
    },
    accentHeaderText: {
        color: "#64F585",
        fontFamily: "SFProDisplayBold",
        fontSize: normalize(20),
        lineHeight: normalize(28),
        marginTop: 32,
        marginBottom: 10
    },
    inlineTextView: {
        flexDirection: 'row'
    },
    underlineText: {
        textDecorationLine: 'underline'
    },
    unorderedListView: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 32,
        minHeight: 'fit-content'
    },
    unorderedListBullet: {
        flex: 1,
        textAlign: 'center',
        fontFamily: "SFProDisplayBold",
        fontSize: normalize(20)
    },
    unorderedListText: {
        flex: 8
    },
    secondaryUnorderedListItem: {
        marginLeft: 32
    },
    accentSectionText: {
        color: "#64F585",
        fontFamily: "SFProDisplayLight",
        fontSize: normalize(18),
        lineHeight: normalize(23)
    },
    imageView: {
        backgroundColor: '#081A34',
        height: 200,
        justifyContent: 'center',
        borderRadius: 8,
        marginTop: 32
    },
    quickNavButton: {
        marginLeft: 8
    },
    sectionButton: {
        backgroundColor: '#232d4b',
        padding: 16,
        borderRadius: 8,
        marginTop: 24,
        alignItems: 'center',
        justifyContent: 'center'
    },
    sectionButtonText: {
        color: '#FFFFFF',
        fontFamily: "SFProDisplayLight",
        fontSize: normalize(20)
    },
    linkText: {
        color: "#4A90E2",
        fontFamily: "SFProDisplayLight",
        fontSize: normalize(18),
        lineHeight: normalize(23),
    },
    tableView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    columnView: {
        flex: 1,
    },
    tableHeaderCellView: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    tableHeaderText: {
        flex: 1,
        color: "#64F585",
        fontFamily: "SFProDisplayBold",
        fontSize: normalize(16),
        lineHeight: normalize(23)
    },
    tableCellView: {
        padding: 8,
        borderColor: '#FFFFFF',
        borderRightWidth: 1,
        flexGrow: 1,
    },
    tableRowText: {
        flex: 1,
        color: "#FFFFFF",
        fontFamily: "SFProDisplayLight",
        fontSize: normalize(18),
        lineHeight: normalize(23)
    },
    tableIcon: {
        color: '#64F585',
        fontSize: normalize(40),
        alignSelf: 'center'
    },
    tableSpacer: {
        height: 23
    }
});