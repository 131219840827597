import React from "react";
import {Animated, StyleSheet, Text, TouchableOpacity} from "react-native";
import CommonColors from "../styles/CommonColors";
import normalize from "react-native-normalize";

class ActionSheet extends React.Component {
    static show({ ...config }, callback) {
        this.actionSheetInstance.showActionSheet({ config }, callback)
    }

    static actionSheetInstance;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fadeAnim: new Animated.Value(0),
            items: []
        }
    }

    showActionSheet({ config }, callback) {
        this.setState({
            items: config.items,
            title: config.title,
            visible: true,
            actionSheetStyles: {...styles.actionSheetStyles, ...config.actionSheetStyles},
            callback
        });

        // Fade the ActionSheet in now.
        Animated.timing(this.state.fadeAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false
        }).start();
    }

    hideActionSheet() {
        Animated.timing(this.state.fadeAnim, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false
        }).start(() => {
            this.setState({ visible: false });
        });
    }

    render() {
        if(this.state.visible) {
            return (
                <TouchableOpacity
                    activeOpacity={1}
                    style={styles.container}
                    onPress={() => this.hideActionSheet()}
                >
                    <Animated.View style={[this.state.actionSheetStyles, {opacity: this.state.fadeAnim}]}>
                        {this.state.title ?
                            <Text style={styles.titleText}>{this.state.title}</Text>
                            :
                            null
                        }
                        {this.state.items.map((item, i) => {
                            return <TouchableOpacity
                                key={`${item}-${i}`}
                                style={styles.itemButton}
                                onPress={() => {
                                    this.state.callback(parseInt(i));
                                    this.hideActionSheet();
                                }}
                            >
                                <Text style={[styles.itemText, item.textColor ? {color: item.textColor} : {}]}>
                                    {typeof item === 'string' ? item : item.text}
                                </Text>
                            </TouchableOpacity>
                        })}
                    </Animated.View>
                </TouchableOpacity>
            );
        } else {
            return null;
        }
    }
}

export default ActionSheet;

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.4)'
    },
    actionSheetStyles: {
        width: '100%',
        justifyContent: 'center',
        alignSelf: 'center',
        position: 'absolute',
        bottom: 0,
        padding: 16,
        elevation: 9,
        backgroundColor: '#262626',
        paddingBottom: normalize(64),
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
    },
    itemButton: {
        padding: 12,
        borderColor: '#b3b3b3',
        borderBottomWidth: 0.25,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0,0,0,1.0)'
    },
    itemText: {
        color: '#FFFFFF',
        fontSize: 20,
        fontFamily: "SFProDisplayLight",
    },
    titleText: {
        alignSelf: 'center',
        color: CommonColors.green,
        fontSize: 20,
        fontFamily: "SFProDisplayLight",
        marginBottom: 16
    }
});