import React from 'react';
import {Image, Linking, ScrollView, Text, View} from "react-native";
import {SafeAreaView} from 'react-native-safe-area-context';
import {StatusBar} from "expo-status-bar";
import Styles from "../../styles/Global";
import TopicStyles from "../../styles/Topics";

export default function Help() {

    const {background, flex, mtLarge, mtSmall} = Styles
    const {
        contentView,
        headerImageView,
        headerText,
        headerView,
        linkText,
        sectionText,
        subHeaderText,
        unorderedListBullet,
        unorderedListText,
        unorderedListView
    } = TopicStyles

    return (
        <SafeAreaView style={background}>
            <StatusBar style="light"/>
            <ScrollView
                style={flex}
                showsVerticalScrollIndicator={false}
            >
                <Image
                    source={require("../../../assets/images/help.jpg")}
                    style={headerImageView}/>
                <View style={headerView}>
                    <Text style={subHeaderText}>Topic</Text>
                    <Text style={headerText}>When to seek professional help</Text>
                </View>
                <View style={contentView}>
                    <Text style={[sectionText, mtSmall]}>Depression and anxiety affect many people, and for some, it can be
                        disabling and life-threatening. You should seek help immediately if you are experiencing any of
                        the following:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Frequent thoughts of suicide, including a
                            specific plan to commit suicide (call 9-1-1 or the National Suicide Prevention Lifeline at <Text
                                style={[linkText, unorderedListText]}
                                onPress={() => {
                                    Linking.openURL('tel:+18002738255')
                                }}
                            >800-273-8255</Text>)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Frequent thoughts of seriously hurting someone
                            else (call 9-1-1)</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text style={[sectionText, unorderedListText]}>Are unable to do the minimum that is required
                            to take care of yourself or others</Text>
                    </View>

                    <Text style={[sectionText]}>Helpful websites on managing depression and anxiety include the
                        following:</Text>
                    <View style={[unorderedListView, mtLarge]}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text
                            style={[linkText, unorderedListText]}
                            onPress={() => {
                                Linking.openURL('https://adaa.org/')
                            }}
                        >https://adaa.org/</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text
                            style={[linkText, unorderedListText]}
                            onPress={() => {
                                Linking.openURL('https://www.samhsa.gov/')
                            }}
                        >https://www.samhsa.gov/</Text>
                    </View>
                    <View style={unorderedListView}>
                        <Text style={[sectionText, unorderedListBullet]}>{'\u2022'}</Text>
                        <Text
                            style={[linkText, unorderedListText]}
                            onPress={() => {
                                Linking.openURL('https://www.nimh.nih.gov/health/index.shtml')
                            }}
                        >https://www.nimh.nih.gov/health/index.shtml</Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}