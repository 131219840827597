import React from 'react';
import AppLoading from 'expo-app-loading';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {useFonts} from "expo-font"
import AuthNavigator from "./src/navigators/AuthNavigator";
import Root from "./src/screens/Root";

export default function App() {
    const [loaded] = useFonts({
        SFProDisplayLight: require("./assets/fonts/SFProDisplayLight.otf"),
        SFProDisplayRegular: require("./assets/fonts/SFProDisplayRegular.otf"),
        SFProDisplaySemibold: require("./assets/fonts/SFProDisplaySemibold.otf"),
        SFProDisplayBold: require("./assets/fonts/SFProDisplayBold.otf")
    });

    if (!loaded) {
        return <AppLoading/>
    }

    return (
        <Root>
            <SafeAreaProvider>
                <AuthNavigator/>
            </SafeAreaProvider>
        </Root>
    );
}
